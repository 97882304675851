import React from 'react';
import './WhyChooseUs.scss';
import FranchiseImage from '../../assets/images/franchise.png';
import CustomerImage from '../../assets/images/customer.png';


export class WhyChooseUs extends React.Component<{}, 
  { showSuperClaimsModal: boolean, showCustomersModal: boolean, showMoreTLCModal: boolean }> {

  constructor(props: any) {
    super(props);
    this.state = {
      showSuperClaimsModal: false,
      showCustomersModal: false,
      showMoreTLCModal: false
    };
  }

  showSubMenu = (input: string) => {
    if(input === 'superclaims') {
      this.setState({ showSuperClaimsModal: true });
    } else if(input === 'lovedbycustomers') {
      this.setState({ showCustomersModal: true });
    } else if(input === 'moretlc') {
      this.setState({ showMoreTLCModal: true });
    }
  }

  hideSubMenu = (input: string, event: any) => {
    if(input === 'superclaims') {
      this.setState({ showSuperClaimsModal: false });
    } else if(input === 'lovedbycustomers') {
      this.setState({ showCustomersModal: false });
    } else if(input === 'moretlc') {
      this.setState({ showMoreTLCModal: false });
    }
  }

  hideSubMenu2 = (event: any) => {
    let targetId = event.target.id;
    if(targetId === 'Super-Simple-claims'
      || targetId === 'loved-by-customers'
      || targetId === 'more-tlc-less-tnc') {
      this.setState({ showSuperClaimsModal: false });
      this.setState({ showCustomersModal: false });
      this.setState({ showMoreTLCModal: false });
    }
  }

  render() {
    return (
      <div className="why-choose-us-trending">
        <section className="section-pad">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <div className="txt-center">
                  <h2 className="section-title">Why choose <span className="brand-color">Eezeeinsure</span>?</h2>
                </div>
                <div className="row">
                    <div className="col-sm-6" data-aos="fade-right" data-aos-duration="2000">
                      <div className="choose-card txt-center">
                        <img className="choose-card__img img-responsive pw-img loaded" alt="Franchisees" src={FranchiseImage} />
                        <div className="choose-card__content">
                          <h4 className="text-xl text-sm-2xl text-black-444 font-black">Franchisees</h4>
                          <p className="text-lg text-sm-xl text-black">High Returns on Capital employed by Franchisee</p>
                          <p className="text-lg text-sm-xl text-black">Potential to grow retail business 10 times in 5 years</p>
                          <p className="text-lg text-sm-xl text-black">Assured Confidentiality of customer data</p>

                          <a data-toggle="modal" onClick={() => this.showSubMenu("superclaims")} className="bold eezee-link" data-target="#Super-Simple-claims">Read More</a>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6" data-aos="fade-left" data-aos-duration="2000">
                      <div className="choose-card txt-center">
                        <img className="choose-card__img img-responsive pw-img loaded" alt="Customers" src={CustomerImage} />
                        <div className="choose-card__content">
                          <h4 className="text-xl text-sm-2xl text-black-444 font-black">Customers</h4>
                          <p className="text-lg text-sm-xl text-black">Confidentiality of customer data</p>
                          <p className="text-lg text-sm-xl text-black">Best priced and curated insurance policies</p>
                          <p className="text-lg text-sm-xl text-black">Doorstep services to key customers</p>
                          
                          <a data-toggle="modal" 
                          onClick={() => this.showSubMenu("moretlc")} 
                          className="bold eezee-link" data-target="#more-tlc-less-tnc">Read More</a>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        { this.state.showSuperClaimsModal && 
          <div className="modal why-choose-us-modal" id="Super-Simple-claims" aria-hidden="false"
            onClick={(event) => this.hideSubMenu2(event)} >
            <div className="modal-outer modal-outer-600">
              <div className="modal-inner">
                <button type="button" onClick={(event) => this.hideSubMenu("superclaims", event)}  className="close modal-close" data-dismiss="modal" aria-label="Close">
                <span className="material-icons">close</span>
                </button>
                <div className="modal-content-wrap">
                  <div className="modal-content">
                    <div>
                      <p className="modal-title">Franchisees</p>
                    </div>
                    <div className="modal-content-text">
                      <div className="modal-content-text__info whychoosusmodalcontent">
                        <ul>
                          <li>Opportunity to work with highly experienced and competent team of Insurance professionals</li>
                          <li>Professional and Transparent Business Relationship and Reward Management</li>
                          <li>Exclusive Geographical Coverage rights for Franchisee</li>
                          <li>High Returns on Capital employed by Franchisee</li>
                          <li>Potential to grow retail business 10 times in 5 years</li>
                          <li>Assured Confidentiality of customer data</li>
                          <li>Increased client walk-in through Eezeeinsure Suvidha Kendra</li>
                          <li>Instant Policy Issuance and Hassle-free claim settlement</li>
                          <li>Customized Digital Marketing Support customized the Franchisee</li>
                          <li>Wide range of insurance products and companies to fulfill customer needs</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        { this.state.showCustomersModal &&  
          <div className="modal why-choose-us-modal" id="loved-by-customers" aria-hidden="false"
            onClick={(event) => this.hideSubMenu2(event)} >
            <div className="modal-outer modal-outer-600">
              <div className="modal-inner">
                <button type="button" 
                onClick={(event) => this.hideSubMenu("lovedbycustomers", event)} 
                className="close modal-close" data-dismiss="modal" aria-label="Close">
                  <span className="material-icons">close</span>
                </button>
                <div className="modal-content-wrap">
                  <div className="modal-content">
                    <div>
                      <p className="modal-title">Loved by Customers</p>
                    </div>
                    <div className="modal-content-text">
                      <div className="modal-content-text__info whychoosusmodalcontent">
                        <ul>
                          <li>**Includes cumulative count of all policies/members/lives on-boarded from inception till 10th&nbsp;August 2021</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        { this.state.showMoreTLCModal && 
          <div className="modal why-choose-us-modal" id="more-tlc-less-tnc" aria-hidden="false"
            onClick={(event) => this.hideSubMenu2(event)}>
            <div className="modal-outer modal-outer-600">
              <div className="modal-inner">
                <button type="button" 
                onClick={(event) => this.hideSubMenu("moretlc", event)}
                className="close modal-close" data-dismiss="modal" aria-label="Close">
                  <span className="material-icons">close</span>
                </button>
                <div className="modal-content-wrap">
                  <div className="modal-content">
                    <div>
                      <p className="modal-title">Customers</p>
                    </div>
                    <div className="modal-content-text">
                      <div className="modal-content-text__info whychoosusmodalcontent">
                        <ul>
                          <li>Instant Policy Issuance and Hassle-free claim settlement</li>
                          <li>Flexibility of choosing from wide range of insurance products and companies to fulfill customer needs</li>
                          <li>Confidentiality of customer data</li>
                          <li>Increasing customer awareness about insurance benefits and customer rights</li>
                          <li>Best priced and curated insurance policies</li>
                          <li>Attractive prizes  / gifts on various policies</li>
                          <li>Doorstep services to key customers</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        
      </div>
    )
  }

}