import React from 'react';
import './ShopKeeperInsurance.scss';

export class ShopKeeperInsurance extends React.Component<{}, { }> {

  render() {
    return (
        <>
          <section className="car-wrapper hero-unit">
            <div className="page-content">
              <div className="container">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="cr-content">
                    <div className="row">
                      <div className="col-lg-8 col-md-8 col-sm-7 car-register-num-content">
                        <div className="cr-header">
                          <h1 className="cr-header">Shop Insurance</h1>
                        </div>
                        <div className="car-register-num-content-image skeleton">
                          &nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-pad">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="text-sm-center mb-30">
                    <h2 className="h2-v2 text-left">Shop Insurance: Buy/Renew Shop Insurance Policy Online</h2>
                  </div>
                </div>
                <div className="col-md-10 col-md-offset-1">
                  <div>
                    <div className="content-info-list">
                      <h3 className="font-bold">What is Shop Insurance?</h3>
                      <p></p>
                      <div>
      Shop Insurance is a type of fire policy which offers protection to the contents and the property of the Shop. It covers loss or damage due to natural disasters or due to accidental fire, loss due to burglary in a Shop. 
    </div>
    <p>&nbsp;</p>
    <h3>What are various Insurance covers offered by Shopkeeper / Shop Insurance Policy?</h3>
    <div>
      Most of some of these covers are available in Shop Insurance Policy offered by many Insurance companies in India:
      <ul>
        <li>Fire - this offers protection against loss or damage to the building (Shop) and contents due to Fire /Lightening/Riot /Storm/Cyclone/ Strikes / Flood etc
</li>
        <li>Burglary  - provides Insurance overage against loss or damage caused by  burglary or attempt of burglary to contents in the insured's Shop
</li>
        <li>Fidelity  -  this insured against financial losses due to fraud or dishonest act of salaried employees
</li>
        <li>Money (in Safe & in transit) -  this clause provides cover due to loss of money due to burglary of cash in the safe place or during transit from Shop to the bank/ATM and from bank to Shop
</li>
        <li>Glass Breakage   - it offers protection against any loss or damage to fixed plain glass caused by an accident, external and visible means
</li>
        <li>Cheque Forgery  -  Financial Loss occurred due to forgery or material alteration of cheques, drafts or any negotiable instruments issued by you or in your favor
</li>
        <li>Public Liability -  this cover legal liability on behalf of the proposer for accidental death or bodily injury to third party and damage to third party property
</li>
        <li>Personal Accident - Bodily injuries because of accident resulting in death or permanent total disability. Some Insurance companies also offer ambulance charges and for carriage of dead body (in event of death)
</li>
        <li>Medical Expenses - this provides Insurance against medical expenses to extent of SI in case of hospitalization due to accident
</li>
<li>Neon Sign -  damage to Neon/Glow Signboard displayed on your Shop due to loss/damage caused by accident /fire/floods /riots etc
</li>
<li>Employer's Liability - provides legal liability protection to employees of the Shop
</li>
<li>Marine transit -  it provides protection against losses /damage caused to goods during movement from godown to Shop
</li>
      </ul> 
    </div>
    <p>&nbsp;</p>
    <h3>What types of Shops are covered in a Shop Insurance Policy?</h3>
    <div>
      A brief list of Shops that can avail of Shop Insurance by many insurers in India are:
      <ul>
        <li>Electronics / Mobile Sales</li>
        <li>Office and Educational Institutions - covers all businesses imparting education such as Colleges / Schools / Coaching centers etc
</li>
        <li>Grocery Shops / General & Kirana Stores
</li>
        <li>Lifestyle & Fitness Shops -  such as Malls / Gyms /  Cloth Showroom   /Spas etc
</li>
        <li>Foods and Eatables - such as restaurants / food trucks / Cafeterias / takeaway joints etc
</li>
        <li>Manufacturing & Processing -  factories / mills etc
</li>
        <li>Healthcare -  such as clinics /hospitals / diagnostic labs /   pharmacy Shops etc
</li>
        <li>Home Repair Services -  electrician / plumbing / carpentry Shops / garages etc
</li>
      </ul>

    </div>
    <p>&nbsp;</p>
    <h3>What are typical exclusions in Shop Insurance Policy?</h3>
    <div>
      These are the typical exclusions in a Shop Insurance policy:
      <ul>
        <li>Loss arising due to war or war like situations </li>
        <li>Loss due to burglary in event of explosions or fire or burglary by insured / family or employees
</li>
        <li>Loss of earnings or profits
</li>
        <li>Loss due to contamination or due to air/water pollution
</li>
        <li>If Actual Sum Insured is less than actual loss in case of fire  - then there will be a proportionate reduction of claim amount payable 
</li>
        <li>No claim will be entertained if the loss/damage is caused by insured / employee of the insured
</li>
        <li>Premises are unoccupied for long period of time (say greater than 30 days) 
</li>
        <li>This policy excludes any cover to livestock, motor vehicles, cycles, money, stamp, bullion, deeds, bonds, bills of exchange, promissory notes, stock or share certificates, business books, manuscripts, documents of any kind, ATM or debit or credit cards (unless previously specifically declared and accepted in proposal by Insurance co. )  
</li>
        <li>It also excludes property illegally acquired, kept, stored in the Shop premises 
</li>
        <li>It does not cover Jewellery, gold ornaments, precious stones, silver items etc
</li>
        <li>If  intimation to police and to company alongwith FIR is not submitted at time of submission of claim documents
</li>
        <li>Loss or damage to electronic equipment due to wear and tear and defects/faults in electronic equipment known to the insured at time of policy commencement
</li>
      </ul>
    </div>
    <p>&nbsp;</p>
    <h3>FAQs</h3>
    <div>
      <div className='font-bold'>What should I do in case I want to lodge a claim under Shop Insurance?</div>
      <div>
        The insured must perform the following steps at time of lodging a claim under Shop Insurance policy:
          <ul>
            <li>Inform the insurer within 24 hours of occurrence of loss or damage</li>
            <li>Inform the police authorities in case of theft or burglary</li>
            <li>Call Fire brigade immediately in event of fire</li>
            <li>Submit duly filled up claim form to Insurance co. and hand over all documents asked for by the surveyor</li>
          </ul>
      </div>
      <br/>
      <div>
        <div  className='font-bold'>How is the premium for Shop Insurance determined?</div>
        <div>Premium for Shop Insurance depends upon</div>
        <ul>
          <li>perils / risks to be covered</li>
          <li>quantum of coverage (Sum Insured)</li>
          <li>any deductibles </li>
          <li>construction of the building</li>
          <li>nature of occupation</li>
          <li>contents in the Shop</li>
          <li>location of the property</li>
          <li>add-on covers like liability cover etc</li>
        </ul>
      </div>
      <br/>
      <div>
        <div  className='font-bold'>What is Market value? How is it different from Agreed value and Reinstatement value?</div>

        <div>Market Value is the cost of buying a similar item (for which loss/damage has occurred) which is calculated by applying depreciation for the age and deduction of the salvage value of the old item insured.
</div>
        <div>Reinstatement Value can be the cost required for rebuilding the asset without considering any depreciation
</div>
        <div>Agreed Value  -  in situations, where it is not possible to assess the exact value of the items (such as art/paintings etc)  -  then it is prudent to arrive at an Agreed value for the sum insured before policy commencement - on mutually Agreed basis
</div>
      </div>
      <br/>
      <div>
        <div  className='font-bold'>What is Stock Cover?</div>
        <div>
          Any contents in the Shop are offered protection against theft, fire or damage.  By covering stock in the Shop, you will get full replacement value - in case of damage or theft
        </div>
      
      </div>
      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
    )
  }

}