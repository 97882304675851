import React from 'react';
import { Header } from '../../components/header/Header';
import { Footer } from '../../components/footer/Footer';
import { SemiFooter } from '../../components/semifooter/SemiFooter';
import { CarInsurance } from '../../components/carinsurance/CarInsurance';
import { HealthInsurance } from '../../components/healthinsurance/Healthinsurance';
import { TravelInsurance } from '../../components/travelinsurance/TravelInsurance';
import { ShopKeeperInsurance } from '../../components/shopkeeperinsurance/ShopKeeperInsurance';
import { HomeInsurance } from '../../components/homeinsurance/HomeInsurance';
import { TwoWheelerInsurance } from '../../components/twowheelerinsurance/TwoWheelerInsurance';
import { GCVInsurance } from '../../components/gcvinsurance/GCVInsurance';
import { PCVInsurance } from '../../components/pcvinsurance/PCVInsurance';
import { AutoRickshawInsurance } from '../../components/autorickshawinsurance/AutoRickshawInsurance';
import { TractorInsurance } from '../../components/tractorinsurance/TractorInsurance';
import { MarineInsurance } from '../../components/marineinsurance/MarineInsurance';
import { PrivacyPolicy } from '../../components/privacypolicy/PrivacyPolicy';
import { AboutUs } from '../../components/aboutus/AboutUs';
import { ContactUs } from '../../components/contactus/ContactUs';
import { CustomerComplaintsPolicy } from '../../components/customercomplaintshandling/CustomerComplaintsPolicy';
import { CustomerRightsPolicy } from '../../components/customerrightspolicy/CustomerRightsPolicy';
import { Disclaimer } from '../../components/disclaimer/Disclaimer';

export class DetailPage extends React.Component<{pagetype: string}, { }> {


  render() {
    return (
      <div className="App">
      <Header/>
      { this.props.pagetype === 'carinsurance' &&
        <CarInsurance/>
      }
      { this.props.pagetype === 'healthinsurance' &&
        <HealthInsurance/>
      }
      { this.props.pagetype === 'travelinsurance' &&
        <TravelInsurance/>
      }
      { this.props.pagetype === 'shopinsurance' &&
        <ShopKeeperInsurance/>
      }
      { this.props.pagetype === 'homeinsurance' &&
        <HomeInsurance/>
      }
      { this.props.pagetype === 'twowheelerinsurance' &&
        <TwoWheelerInsurance/>
      }
      { this.props.pagetype === 'gcvinsurance' &&
        <GCVInsurance/>
      }
      { this.props.pagetype === 'pcvinsurance' &&
        <PCVInsurance/>
      }
      { this.props.pagetype === 'autorickshawinsurance' &&
        <AutoRickshawInsurance/>
      }
      { this.props.pagetype === 'tractorinsurance' &&
        <TractorInsurance/>
      }
      { this.props.pagetype === 'marineinsurance' &&
        <MarineInsurance/>
      }
      { this.props.pagetype === 'privacypolicy' &&
        <PrivacyPolicy/>
      }
      { this.props.pagetype === 'aboutus' &&
        <AboutUs/>
      }
      { this.props.pagetype === 'contactus' &&
        <ContactUs/>
      }
      { this.props.pagetype === 'complaintshandling' &&
        <CustomerComplaintsPolicy/>
      }
      { this.props.pagetype === 'customerrightpolicy' &&
        <CustomerRightsPolicy/>
      }
       { this.props.pagetype === 'disclaimer' &&
        <Disclaimer/>
      }
      <SemiFooter/>
      <Footer/>
    </div>
    )
  }

}