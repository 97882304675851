import React from 'react';
import {ProductsMenu} from './ProductsMenu';
import {ClaimsMenu} from './ClaimsMenu';
import {SupportMenu} from './SupportMenu';
import {FranchiseMenu} from './FranchiseMenu'
import { MobileNav } from './MobileNav';
import './header.scss';
import HeaderLogo from '../../assets/images/eezeeinsure-logo.svg';

export class Header extends React.Component<{}, { showMobileMenu: boolean }> {

  constructor(props: any) {
    super(props);
    this.state = {
      showMobileMenu: false
    };
  }


  toggleMobileMenu = () => {
    if(this.state.showMobileMenu) {
      this.setState({ showMobileMenu: false });
    } else {
      this.setState({ showMobileMenu: true });
    }
  }

  hideMobileMenu = () => {
    this.setState({ showMobileMenu: false });
  }

  render() {
    return (
      <div className="header">
        <div className="container">
          <div className='header-wrapper'>
            <div className='menu-logo-wrap'>
              <span className="bar-icon d-block d-sm-none">
                <span className="hamburger-inner" onClick={() => this.toggleMobileMenu()}>
                  <span className="bar"></span> 
                  <span className="bar"></span> 
                  <span className="bar"></span> 
                </span>
              </span>
              <a href="/">
                <img src={HeaderLogo} alt=""/>
              </a>
            </div>
            <div className='header-right-wrap'>
              <ul>
                <li>
                  <a href="#">
                    <span>Products</span> 
                    <span className="material-icons eezee-arrow-icon">expand_more</span>
                  </a>
                  <ProductsMenu/> 
                </li>
                <li className='relt-menu-item'>
                  <a href="#">
                  <span>Claims</span> 
                    <span className="material-icons eezee-arrow-icon">expand_more</span>
                  </a>
                  <ClaimsMenu />
                </li>
                <li className='relt-menu-item'>
                  <a href="#">
                  <span>Franchisee List</span>
                    <span className="material-icons eezee-arrow-icon">expand_more</span>
                  </a>
                  <FranchiseMenu />
                </li>
                <li>
                  <a href="/aboutus">About Us</a>
                </li>
              </ul>
              <ul className='support-menu'>
                <li>
                  <a href="#">
                    Get Help
                    <span className="material-icons eezee-arrow-icon">expand_more</span> 
                  </a>
                  <SupportMenu />
                </li>
              </ul>
            </div> 
          </div>
        </div>
        <MobileNav showMobileMenu={this.state.showMobileMenu}/>
        { this.state.showMobileMenu && 
          <div className='backdrop-overlay active'
          onClick={() => this.hideMobileMenu()}></div>
        }
      </div>
    );
  }
}