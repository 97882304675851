import React from 'react';
import './TravelInsurance.scss';

export class TravelInsurance extends React.Component<{}, {}> {

  render() {
    return (
        <>
          <section className="car-wrapper hero-unit">
            <div className="page-content">
              <div className="container">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="cr-content">
                    <div className="row">
                      <div className="col-lg-8 col-md-8 col-sm-7 car-register-num-content">
                        <div className="cr-header">
                          <h1 className="cr-header">Travel Insurance</h1>
                        </div>
                        <div className="car-register-num-content-image skeleton">
                          &nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-pad">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="text-sm-center mb-30">
                    <h2 className="h2-v2 text-left">Travel Insurance: Buy/Renew Travel Insurance Policy Online</h2>
                  </div>
                </div>
                <div className="col-md-10 col-md-offset-1">
                  <div>
                    <div className="content-info-list">
                      <h3 className="font-bold">What is Travel Insurance?</h3>
                      <p></p>
                      <div>
          For travellers, there is a product which offers protection against different types of emergencies eg: medical expenses or unforeseen losses/damages. These medical expenses could entail costs on medical evaluation and repatriation, detaining checked-in baggage or absolute loss of checked-in baggage, flight cancellations and /or flight missed due to forgotten connections, financial emergency assistance.
        </div>
        <p>&nbsp;</p>
        <h3>Why do I need Travel Insurance?</h3>
        <div>
          Any person travelling abroad must be aware of the financial risks / possible loss that may occur -  some type of unforeseen risks can be:
          <ul>
            <li>Hospitalization due to medical  /accident related causes (typically medical expenses in foreign countries is 3 to 5 times expensive than in India.
</li>
            <li>Unforeseen Natural Calamities or Human made disasters.
</li>
            <li>Personal belongings that travellers lose are Passport(s) /  License or ID Card  / Bank credit & debit cards / Mobile phone /  Handbags etc  and this can cause financial loss and stress/anxiety.
</li>
            <li>Falling prey to travel related scams / thugs in tourism dominated countries.
</li>
            <li>Flight Delays /Cancellations lead to financial loss and this can be very inconvenienent.
</li>
            <li>Travel Insurance is mandatory in 30+ countries including USA/Canada / Schengen countries in Europe /Russia etc
</li>
          </ul>

        </div>
        <p>&nbsp;</p>
        <h3>What are insurance covers available under Travel Insurance?</h3>
        <div>
          The following covers are typically covered in any travel insurance policy - 
        </div>
        <br/>
        <div>
          Medical Insurance Cover - 
          <ul>
            <li>Emergency Medical /Hospitalization Expenses or medical care costs incurred by the insured while traveling abroad</li>
            <li>Emergency Dental Expenses -  only in case of an accident if the insured needs emergency dental care -  then travel insurance will cover for such expenses to the extent of sum insured</li>
            <li>Evacuation on medical grounds -  travel insurance covers all expenses incurred by the insured for medical evacuation to a nearby hospital</li>
            <li>Hospital Daily Cash allowance  - Few insurance policies cover petty expenses incurred during hospitalization abroad
</li>
            <li>Body Repatriation -  in the unfortunate situation of insured dying abroad -  then the travel insurance policy covers the cost incurred to bring the mortal remains to India
</li>
            <li>Permanent Disability -  while travelling /staying abroad if the insured meets with an accident leading to permanent disability  -  then the insurance co. makes a one-time fixed amount compensation
</li>
            <li>Accidental Death   -  in case of fatal accident on a foreign trip, the nominee of the insured or insured's family will get one-time fixed compensation from the insurance co.
</li>
          </ul>
        </div>
<br/>
        <div>
          Baggage Related Insurance Cover -
          <ul>
            <li>Loss of Passport  -  most travel insurance policies cover the cost of obtaining a duplicate passport if the original passport gets lost during overseas travel 
</li>
            <li>Loss of Check-in baggage  - if the insured loses the check-in baggage then the overseas travel insurance takes care of the replacement of items lost.
</li>
            <li>Check-in baggage delay -  Travel insurance cover include reimbursement of expenses to purchase essential items till the insured recevies the checked-in baggage
</li>
          </ul> 

        </div>
        <br/>
        <div>
          Journey Related Insurance Cover - 
          <ul>
            <li>Personal Liability cover -  in case of an unforeseen accident leading to damage caused to third party life/property then the insurance company will compensate the third party to extent of sum insured -  this will help avoid legal issues
</li>
            <li> Financial Emergency Assistance -   if  the insured gets robbed abroad or fallen prey to theft -  then the insurance co. will provide emergency cash to the insured
</li>
            <li>Hijack Distress Allowance  -   if under a rare and unfortunate scenario of airplane getting hijacjed -  then the insurance company will provde fixed compensation for the stress causes to the insured travelling in hijacked plane
</li>
          </ul>
        </div>
        <p>&nbsp;</p>
        <h3>What are different travel insurance plans generally offered by insurance companies?</h3>
        <div>
          The typical international travel insurance plan are of following types:
          <ul>
            <li>Student Travel Insurance</li>
            <li>International Travel Insurance</li>
            <li>Corporate / Group Travel Insurance</li>
            <li>Senior Citizen Travel Insurance  (offered by select companies)</li>
          </ul> 
        </div>
        <p>&nbsp;</p>
        <h3>FAQs</h3>
        <div className='font-bold'>What are different factors that determince the cost of international travel insurance?</div>
        <div>
          <div>The premium for international travel insurance is determined by some of these factors -</div>
          <ul>
            <li>Sum Insured (higher sum insured implies higher insurance premium)</li>
            <li> Number of travellers</li>
            <li>Age of all travellers  (higher age demands higher insurance premium)</li>
            <li>Destination Countries (high risk countries come with high travel insurance premium)</li>
            <li>Trip Duration (maximum duration is 180 days)  - longer the trip higher will be the premium</li>
            <li>Single Trip or Multi-trip travel </li>
            <li>Add-on covers as allowed by  specifc insurance policy/ plan opted for   - such as home burglary protection, fire insurance, study interruption coverage etc</li>

          </ul>
          <br/>
          <div  className='font-bold'>Is Hotel Booking Cancellation covered under International Travel Insurance covers?</div>
          <div>Few Travel Insurance Cos. Offer "hotel booking cancellation"costs to the extent of refundable room rent on selective travel insurance policies
          </div>


          <br/>
            <div  className='font-bold'>Is Travel Insurance valid for multiple trips?</div>
          <div>Most insurance companies offer single trip international travel insurance which can be extended through prior intimation  (period ranges from 4 days to 180 days) -  however select  travel insurance companies offer multi-trip insurance plans also  - 
          Such multi-trip insurance plans are designed for those travellers who travel often or more than once in a year. It stands to cover round trip journeys for a specified time period, which is usually one year and is very much preferred by frequent corporate / business sector travellers"
          </div>
          <br/>
          <div  className='font-bold'>Apart from ususal factors that impact premium such as age / desitnation country / duration of trip etc -  it will be prudent for insured to check the following -</div>
          <ul>
            <li>Exclusions and medical conditions not covered</li>
            <li>Pleasure or Adventure trip</li>
            <li>Existing Medical Conditions  (to confirm if insurer will pay in case of claim)</li>
            <li>Add-on covers which can meet your specific requirement</li>
            <li>Claim Settlement Reputation and Speed</li>
            <li>Schengen check: There are some peculiarities when it comes to travel insurance -  where insurance requirements are bit different for Schengen countries</li>

          </ul>
        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
    )
  }

}