import React from 'react';
import './Disclaimer.scss';

export class Disclaimer extends React.Component<{}, { }> {

  render() {
    return (
        <>
        <title>Disclaimer</title>
          <section className="car-wrapper hero-unit">
            <div className="page-content">
              <div className="container">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="cr-content">
                    <div className="row">
                      <div className="col-lg-8 col-md-8 col-sm-7 car-register-num-content">
                        <div className="cr-header">
                          <h1 className="cr-header">Disclaimer</h1>
                        </div>
                        <div className="car-register-num-content-image skeleton">
                          &nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-pad">
            <div className="container">
              <div className="row">
                <div className="col-md-10 col-md-offset-1">
                  <div>
                    <div className="content-info-list">
                      
                    <div>The information contained in this website is for general information purposes only. The information is provided by Eezeeinsure.com and while we endeavour to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk</div>
                    <p>&nbsp;</p>
        <div>  The information contained in this website is for general information purposes only. The information is provided by Eezeeinsure.com and while we endeavour to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk
</div>
  <p>&nbsp;</p>
  <div> Through this website you are able to link to other websites which are not under the control of Eezeeinsure.com.  Eezeeinsure.com  has no control over the nature, content and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them
  Eezeeinsure.com may contain copyrighted content not authorized for use by the owner. Eezeeinsure.com’s use of copyrighted content falls under the guidelines of fair use
 </div>
 <p>&nbsp;</p>
  <div>  Eezeeinsure.com wishes to inform its readers that the views, thoughts, and opinions expressed in the chat / blogs belong solely to the author, and not necessarily reflects the official position or policy of Eezeeinsure.com or its parent organization. 
</div>
<p>&nbsp;</p>
  <div>Eezeeinsure.com would like its readers to know that if there are any errors in the material, or omission of information that turns out to be material, the site-owner/author isn't to be held liable for damages that arise out of them.
  Every effort is made to keep the website up and running smoothly. However, Eezeeinsure.com takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control
 </div>
   
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
    )
  }

}