import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import 'material-icons/iconfont/material-icons.css';
import { DetailPage } from './pages/detailpage/DetailPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="carinsurance" element={<DetailPage pagetype={"carinsurance"} />} />
      <Route path="twowheelerinsurance" element={<DetailPage pagetype={"twowheelerinsurance"} />} />
      <Route path="gcvinsurance" element={<DetailPage pagetype={"gcvinsurance"} />} />
      <Route path="autorickshawinsurance" element={<DetailPage pagetype={"autorickshawinsurance"} />} />
      <Route path="pcvinsurance" element={<DetailPage pagetype={"pcvinsurance"} />} />
      <Route path="tractorinsurance" element={<DetailPage pagetype={"tractorinsurance"} />} />
      <Route path="healthinsurance" element={<DetailPage pagetype={"healthinsurance"} />} />
      <Route path="travelinsurance" element={<DetailPage pagetype={"travelinsurance"} />} />
      <Route path="marineinsurance" element={<DetailPage pagetype={"marineinsurance"} />} />
      <Route path="homeinsurance" element={<DetailPage pagetype={"homeinsurance"} />} />
      <Route path="shopinsurance" element={<DetailPage pagetype={"shopinsurance"} />} />
      <Route path="privacypolicy" element={<DetailPage pagetype={"privacypolicy"} />} />
      <Route path="aboutus" element={<DetailPage pagetype={"aboutus"} />} />
      <Route path="contactus" element={<DetailPage pagetype={"contactus"} />} />
      <Route path="complaintshandling" element={<DetailPage pagetype={"complaintshandling"} />} />
      <Route path="customerrightpolicy" element={<DetailPage pagetype={"customerrightpolicy"} />} />
      <Route path="disclaimer" element={<DetailPage pagetype={"disclaimer"} />} />
    </Routes>
  </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
