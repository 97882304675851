import React from 'react';
import './header.scss';

export class MobileNav extends React.Component<{ showMobileMenu: boolean },
  {
    showProductsAcc: boolean,
    showHealthAcc: boolean,
    showOtherAcc: boolean,
    showClaimsAcc: boolean,
    showHealthClaimAcc: boolean,
    showMotorClaimAcc: boolean,
    showCovid19AssistanceAcc: boolean,
    showClaimStatusAcc: boolean,
    showRenewalsAcc: boolean,
    showBecomeAnAgentAcc: boolean,
  }> {

  constructor(props: any) {
    super(props);
    this.state = {
      showProductsAcc: false,
      showHealthAcc: false,
      showOtherAcc: false,
      showClaimsAcc: false,
      showHealthClaimAcc: false,
      showMotorClaimAcc: false,
      showCovid19AssistanceAcc: false,
      showClaimStatusAcc: false,
      showRenewalsAcc: false,
      showBecomeAnAgentAcc: false
    }
  }

  toggleAcc = (str: string) => {

    if (str === 'claimsacc') {
      if (this.state.showClaimsAcc) {
        this.setState({ showClaimsAcc: false });
      } else {
        this.setState({ showClaimsAcc: true });
      }
    } else if (str === 'healthclaimsacc') {
      if (this.state.showHealthClaimAcc) {
        this.setState({ showHealthClaimAcc: false });
      } else {
        this.setState({ showHealthClaimAcc: true });
      }
    } else if (str === 'motorclaimsacc') {
      if (this.state.showMotorClaimAcc) {
        this.setState({ showMotorClaimAcc: false });
      } else {
        this.setState({ showMotorClaimAcc: true });
      }
    } else if (str === 'covid19assistanceacc') {
      if (this.state.showCovid19AssistanceAcc) {
        this.setState({ showCovid19AssistanceAcc: false });
      } else {
        this.setState({ showCovid19AssistanceAcc: true });
      }
    } else if (str === 'claimsstatus') {
      if (this.state.showClaimStatusAcc) {
        this.setState({ showClaimStatusAcc: false });
      } else {
        this.setState({ showClaimStatusAcc: true });
      }
    } else if (str === 'renewals') {
      if (this.state.showRenewalsAcc) {
        this.setState({ showRenewalsAcc: false });
      } else {
        this.setState({ showRenewalsAcc: true });
      }
    } else if (str === 'becomeanagent') {
      if (this.state.showBecomeAnAgentAcc) {
        this.setState({ showBecomeAnAgentAcc: false });
      } else {
        this.setState({ showBecomeAnAgentAcc: true });
      }
    }

  }

  toggleProductsAcc = () => {
    if (this.state.showProductsAcc) {
      this.setState({ showProductsAcc: false });
    } else {
      this.setState({ showProductsAcc: true });
    }
  }

  toggleHealthAcc = () => {
    if (this.state.showHealthAcc) {
      this.setState({ showHealthAcc: false });
    } else {
      this.setState({ showHealthAcc: true });
    }
  }

  toggleOtherAcc = () => {
    if (this.state.showOtherAcc) {
      this.setState({ showOtherAcc: false });
    } else {
      this.setState({ showOtherAcc: true });
    }
  }



  render() {
    return (
      <>
        <div
          className={
            "header-mb-nav " +
            (this.props.showMobileMenu ? "active " : "inactive")
          }>
          {/* <div className='header-mb-nav-inner'>
            <div className='section-top'>
              <div className="navbar-collpase__content">
                <span className="phone-link">
                  <img className="user-icon-mobile" alt="mail" src="https://d2h44aw7l5xdvz.cloudfront.net/assets/img/sign-in-user-icon.png" />
                </span>
                <div className="navbar-collpase-phone" id="sidemenu-user-sign">
                  <a href="#" className="sign-in-link-sidemenu" id="sign-in-link-sidemenu" >Sign In</a>
                </div>
                <span className="material-icons eezee-right-arrow-icon">chevron_right</span>
              </div>
            </div>
          </div> */}
          <ul className='nav navbar-mb'>
            <li className="nav__list nav__list-container accordions no-border">
              <a className="nav__link nav-category">Products</a>
              <div className="navbar-dropdown-inner">
                <h3 className="nav-group-title"
                  onClick={() => this.toggleProductsAcc()}>Motor Insurance
                  {!this.state.showProductsAcc && <span className="material-icons eezee-right-arrow-icon">expand_more</span>}
                  {this.state.showProductsAcc && <span className="material-icons eezee-right-arrow-icon">expand_less</span>}
                </h3>
                {this.state.showProductsAcc &&
                  <ul className="nav nav-group-container">
                    <li className="nav__list">
                      <a className="nav__link nav__link--light" href="/carinsurance">
                        <span>Car Insurance</span>
                      </a>
                    </li>
                    <li className="nav__list">
                      <a className="nav__link nav__link--light" href="/twowheelerinsurance">
                        <span>Two Wheeler Insurance</span>
                      </a>
                    </li>
                    <li className="nav__list">
                      <a className="nav__link nav__link--light" href="/gcvinsurance">
                        <span>Goods Carrying Vehicle Insurance</span>
                      </a>
                    </li>
                    <li className="nav__list">
                      <a className="nav__link nav__link--light" href="/autorickshawinsurance">
                        <span>Auto Rickshaw Insurance</span>
                      </a>
                    </li>
                    <li className="nav__list">
                      <a className="nav__link nav__link--light" href="/pcvinsurance">
                        <span>Passenger Carrying Vehicle</span>
                      </a>
                    </li>
                    <li className="nav__list">
                      <a className="nav__link nav__link--light" href="/tractorinsurance">
                        <span>Tractor Insurance</span>
                      </a>
                    </li>
                  </ul>
                }
              </div>
              <div className="navbar-dropdown-inner">
                <h3 className="nav-group-title"
                  onClick={() => this.toggleHealthAcc()}>Health Insurance and Other Products
                  {!this.state.showHealthAcc && <span className="material-icons eezee-right-arrow-icon">expand_more</span>}
                  {this.state.showHealthAcc && <span className="material-icons eezee-right-arrow-icon">expand_less</span>}
                </h3>
                {this.state.showHealthAcc &&
                  <ul className="nav nav-group-container">
                    <li className="nav__list">
                      <a className="nav__link nav__link--light" href="/healthinsurance">
                        <span>Health Insurance</span>
                      </a>
                    </li>
                    <li className="nav__list">
                      <a className="nav__link nav__link--light" href="/travelinsurance">
                        <span>Travel Insurance</span>
                      </a>
                    </li>
                    <li className="nav__list">
                      <a className="nav__link nav__link--light" href="/marineinsurance">
                        <span>Marine Insurance</span>
                      </a>
                    </li>
                    <li className="nav__list">
                      <a className="nav__link nav__link--light" href="/homeinsurance">
                        <span>Home Insurance</span>
                      </a>
                    </li>
                    <li className="nav__list">
                      <a className="nav__link nav__link--light" href="/shopinsurance">
                        <span>Shop Insurance</span>
                      </a>
                    </li>
                  </ul>
                }
              </div>
            </li>

            <li className="nav__list nav__list-container non-accordion">
              <a className="nav__link nav-category">Claims</a>
              <div className="navbar-dropdown-inner">
                <ul className="nav nav-group-container nav-direct-links">
                  <li className="nav__list">
                    <a className="nav__link nav__link--light" href="/claims">
                      <span>Claim Intimation</span>
                    </a>
                  </li>
                  <li className="nav__list">
                    <a className="nav__link nav__link--light" href="https://api.whatsapp.com/send?phone=9915070204&amp;text=Hi">
                      <span>Claim Status</span>
                    </a>
                  </li>
                </ul>
              </div>



              {/* <div className="navbar-dropdown-inner">
                <a href="#">
                  <h3 className="nav-group-title">File a Claim 
                  <span className="material-icons eezee-right-arrow-icon">chevron_right</span>
                  </h3>
                </a>
              </div>
              <div className="navbar-dropdown-inner">
                <a href="#">
                  <h3 className="nav-group-title">File Health Claim <span className="material-icons eezee-right-arrow-icon">chevron_right</span>
                  </h3>
                </a>
              </div>
              <div className="navbar-dropdown-inner">
                <a href="#">
                  <h3 className="nav-group-title">File Motor Claim for Garages <span className="material-icons eezee-right-arrow-icon">chevron_right</span>
                  </h3>
                </a>
              </div>
              <div className="navbar-dropdown-inner">
                <a href="#">
                  <h3 className="nav-group-title">COVID-19 Assistance <span className="material-icons eezee-right-arrow-icon">chevron_right</span>
                  </h3>
                </a>
              </div>
              <div className="navbar-dropdown-inner">
                <a href="https://api.whatsapp.com/send?phone=9915070204&amp;text=Hi">
                  <h3 className="nav-group-title">Claim Status <span className="material-icons eezee-right-arrow-icon">chevron_right</span>
                  </h3>
                </a>
              </div> */}
            </li>

            <li className="nav__list nav__list-container non-accordion">
              <a className="nav__link nav-category">Franchisee List</a>
              <div className="navbar-dropdown-inner">
                <ul className="nav nav-group-container nav-direct-links">
                  <li className="nav__list">
                    <a className="nav__link nav__link--light" href="#">
                      <span>Mumbai</span>
                    </a>
                  </li>
                  <li className="nav__list">
                    <a className="nav__link nav__link--light" href="#">
                      <span>Chandigarh</span>
                    </a>
                  </li>
                  <li className="nav__list">
                    <a className="nav__link nav__link--light" href="#">
                      <span>Mohali</span>
                    </a>
                  </li>
                  <li className="nav__list">
                    <a className="nav__link nav__link--light" href="#">
                      <span>Panchkula</span>
                    </a>
                  </li>
                </ul>
              </div>

              {/* <div className="navbar-dropdown-inner">
                <a href='#'>
                  <h3 className="nav-group-title">Renewals <span className="material-icons eezee-right-arrow-icon">chevron_right</span>
                  </h3>
                </a>
              </div>
              <div className="navbar-dropdown-inner">
                <a href="#">
                  <h3 className="nav-group-title">Become an Agent <span className="material-icons eezee-right-arrow-icon">chevron_right</span>
                  </h3>
                </a>
              </div> */}
            </li>
          </ul>

          <ul className="nav navbar-nav nav--company hide-header-option">
            <li className="nav__list">
              <a href="/aboutus" className="nav__link">about</a>
            </li>
            <li className="nav__list nav__list-a">
              <a href="contactus" className="nav__link">contact</a>
            </li>
            {/* <li className="nav__list  nav__list-a">
              <a href="#" className="nav__link">careers</a>
            </li> */}
          </ul>
        </div>
      </>
    );
  }

}