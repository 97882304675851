import React from 'react';
import './CustomerComplaintsPolicy.scss';

export class CustomerComplaintsPolicy extends React.Component<{}, { }> {

  render() {
    return (
        <>
          <section className="car-wrapper hero-unit">
            <div className="page-content">
              <div className="container">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="cr-content">
                    <div className="row">
                      <div className="col-lg-8 col-md-8 col-sm-7 car-register-num-content">
                        <div className="cr-header">
                          <h1 className="cr-header">Complaint Handling Process</h1>
                        </div>
                        <div className="car-register-num-content-image skeleton">
                          &nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-pad">
            <div className="container">
              <div className="row">
                <div className="col-md-10 col-md-offset-1">
                  <div>
                    <div className="content-info-list">
                      <div>
                      Any customer must be made aware about difference between a query and a complaint before the customer reads about the complaint handling process.

<p>A query is a any doubt or enquiry made by a customer. It can also be cross-checking for clarification or a customer seeking additional information. A query can also pertain to customer enquiring or cross checking before the specified turnaround time (TAT) expires.  A query can also be another request made by a customer or also a customer seeking update on status or progress of a previous request / service / insurance product purchased or opted for. </p>
<p>A complaint is a grievance or dissatisfaction or protest or grumble made by a customer disputing non–conformance of services or general insurance products or of ezeeinsure.com’s processes. A complaint can also be a mistake or error committed by ezeeinsure.com’s staff.</p>
<ul>
  <li>Once a customer contacts a eezeeinsure.com employee, the employee will acknowledge their mistake(s) promptly and make immediate efforts to correct these mistakes and errors</li>
  <li>Any customer or associate has the right to lodge a complaint against any deficient service or product or employee misbehaviour.
</li>
  <li>To resolve the complaint, the customer can write to eezeeinsure.com or call our contact centre. If the complaint is not satisfactorily resolved immediately then the customer will be provided with a unique complaint reference number (CRM)
</li>
  <li>Each complaint category has been internally assigned a resolution Turnaround time by eezeeinsure.com.
</li>
  <li>All complaints not resolved within internal Turnaround Time (TAT) will automatically get escalated to eezeeinsure.com’s senior management.
</li>
  <li>If the complaints are not resolved by Eeezeeinsure.com, then the customer has the right to  lodge complaint with respective insurance company or with IRDA.
</li>
  <li>Eezeeinsure.com will display on its website an email id where the customer can lodge a complaint or seek update/feedback on complaint resolution status.
</li>
  <li>Eezeeinsure.com will analyse all complaints and queries received through any mode every quarter. The objective of this exercise is to determine the root cause(s) and the quantum (persistency) of the complaints. This will be used to make changes in our internal customer service request handling process(es).
</li>
  <li>Each complaint will be considered resolved only upon written confirmation provided by our contact team to the customer about satisfactory resolution of the customer complaint. 
</li>
  <li>Each month – eezeeinsure.com will hold a online interaction with all its customers and seek their feedback on products /services / complaints /grievances not resolved. The schedule and mode of such interaction will be published on the website atleast 7 days before the scheduled interaction
</li>
  <li>The official signature of each eezeeinsure.com employee will contain the escalation matrix of their reporting authority (till atleast skip level seniors)
</li>
</ul>

                      </div>  

                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
    )
  }

}