import React from 'react';
import './footer.scss';

export class Footer extends React.Component<{}, { showProductsSubMenu: boolean }> {

  constructor(props: any) {
    super(props);
    this.state = {
      showProductsSubMenu: false
    };
  }

  showSubMenu = () => {
    this.setState({ showProductsSubMenu: true });
  }

  hideSubMenu = () => {
    this.setState({ showProductsSubMenu: false });
  }

  render() {
    return (
      <div className="footerbdband section">
        <div className="secondary-footer">
          <div className="container">
            <div className="row">
              <div className="bd-row col-sm-12 clearfix">
                <div className="legacy-link-menu">
                  <span className="footer-meta">
                    <a href="/disclaimer" rel="nofollow">Disclaimer</a>
                  </span>
                  <span className="footer-meta ">
                    <a href="/privacypolicy" target="_blank" rel="nofollow">Privacy Policy</a>
                  </span>
                  <span className="footer-meta">
                    <a href="/complaintshandling" rel="nofollow">Customer complaints policy</a>
                  </span>
                  <span className="footer-meta f-meta-l">
                    <a href="/customerrightpolicy" rel="nofollow">Customer Rights policy</a>
                  </span>
                </div>
                <p className="txt-white txt-left l-float">Two Hawks Assurance and HR Services Private Limited - Registered Office Address - Eezeeinsure, 204 Sector 21 A Chandigarh 
160022</p>
                <p className="txt-white txt-left l-float">Copyright © 2022 Eezee Insure, All Right Reserved</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}