import React from 'react';
import './WhatWeDo.scss';

export class WhatWeDo extends React.Component<{}, { }> {

  render() {
    return (
      <div className="what-we-do-container" data-aos="zoom-in-up" data-aos-duration="2000">
        <section className="section-pad">
          <div className="container">
          <div className="row">
          <div className="col-md-10 offset-md-1">
          <h2 className="section-title text-center">What we do?</h2>
          <ul>
            <li>Provide all General Insurance products to Retail / SME customers</li>
            <li>Focus on Motor / Health / Fire Insurance</li>
            <li>Use Technology to ensure immediate Policy issuance</li>
            <li>Provide customized Policy and Doorstep services for key SME customers</li>
            <li>Ensure Customer Delight by handholding during entire claims process</li>
            <li>Create Franchisees to improve “reach to customers”</li>
            <li>Emphasize on selective product segments to maintain low claims ratio</li>
            <li>Work with leading insurance companies to ensure wide product offering to customers</li>
            <li>Work closely with Franchisees to improve their sales and customer base</li>
          </ul>
          </div>
          </div>
          </div>
        </section>
      </div>
    )
  }

}