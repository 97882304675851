import React from 'react';
import './HealthInsurance.scss';

export class HealthInsurance extends React.Component<{}, { showMobileMenu: boolean }> {

  constructor(props: any) {
    super(props);
    this.state = {
      showMobileMenu: false
    };
  }


  toggleMobileMenu = () => {
    if(this.state.showMobileMenu) {
      this.setState({ showMobileMenu: false });
    } else {
      this.setState({ showMobileMenu: true });
    }
  }

  hideMobileMenu = () => {
    this.setState({ showMobileMenu: false });
  }

  render() {
    return (
        <>
          <section className="car-wrapper hero-unit">
            <div className="page-content">
              <div className="container">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="cr-content">
                    <div className="row">
                      <div className="col-lg-8 col-md-8 col-sm-7 car-register-num-content">
                        <div className="cr-header">
                          <h1 className="cr-header">Health Insurance</h1>
                        </div>
                        <div className="car-register-num-content-image skeleton">
                          &nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-pad">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="text-sm-center mb-30">
                    <h2 className="h2-v2 text-left">Health Insurance: Buy/Renew Health Insurance Policy Online</h2>
                  </div>
                </div>
                <div className="col-md-10 col-md-offset-1">
                  <div>
                    <div className="content-info-list">
                      <h3 className="font-bold">What is Health Insurance?</h3>
                      <p></p>
                      
                      <div>
                      Health Insurance or Medical Insurance is a type of Insurance that covers the whole or a part of the risk of a person incurring medical expenses arising due to an illness or accidental injury etc. These expenses can be related to hospitalisation costs or cost of medicines or doctor consultation fees  or laboratory tests etc.
                      </div>
                      <p>&nbsp;</p>
        <h3>Why is Health Insurance important?</h3>
        <div>
          Over the year, medical treatment and hospitalization expenses have risen multifold.  This is making cost of treating illnesses very high. It is therefore prudent to seek cover for meeting ever-rising hospitalization expenses. Hence you must buy health insurance for the reasons shared below:
          <ul>
            <li>Protection against expensive medical treatment for health emergencies / hospitalization   -  It is possile that high medical costs can eat into your financial savings especially if there is critical illness or hospitalization due to medical emergency. To avoid this you must purchase medical / health insurance policy.
</li>
            <li>Overcome high Medical Inflation - A health insurance policy will help the insured to pay his/her medical bills, including pre and post-hospitalization expenses. It will also help the insured plan against ever-rising medical costs in future as well.  
</li>
            <li>Ability to afford Quality Medical Treatment - Purchasing medical insurance helps the insured to seek good/high quality medical treatment and healthcare which otherwise he/she could not afford
</li>
            <li>Able to afford treatment for critical illnesses  / lifestle diseases  -  due to changing lifestyle more and more people are falling sick due to critical illnesses like cancer / heart attack / kidney failure /  liver sclerosis /  heart valve replacement /  Parkinson's disease /  Alzheimer's disease /  Brain Tumour /  Chronic Liver disease /  Muscular Dystrophy etc
</li>
            <li>Benefit by availing Cashless Hospitalization   -  Having health insurance allows the insured to avail of cashless hospitalization facility at any of the network hospitals of your insurance company.  
</li>
            <li>Avail Tax Benefits - Government is encouraging people to purchase medical insurance and hence has provided for tax savings on the health insurance premium  paid under Section 80D. 
</li>
          </ul>
        </div>
        <p>&nbsp;</p>
        <h3>Is Medical Insurance different from Health Insurance?</h3>
        <div>
          Medical insurance will provide you insurance cover for hospitalization only, Pre-specified ailments and accidents -  with Pre-capped/Pre-specified amount.  However health insurance is a comprehensive cover that provides protection against hospitalization expenses, Pre-hospitalization expenses, Post-hospitalization expenses and ambulance expenses.
        </div>
        <p>&nbsp;</p>
        <h3>What factors should you consider while deciding on which health insurance plan to purchase?</h3>
        <div>
          You should evaluate the various health insurance plans on these factors:
          <ul>
            <li>Medical Insurance Benefits and Sum Insured  -  any healthcover must be selected basis insured's age and existing medical history. The insurance cover benefits /  waiting period are also important considerations
</li>
            <li>Network Hospitals Coverage -  Having greater number of network hospitals will ensure cashless claim settlement at all these hospitals. TPAs also help in processing cashless hospitalization facilities for patients.  
</li>
            <li>Lifetime Renewability Facility  -  You must finalize the health insurer which allows the insured to have lifetime renewability clause in their policies. This crucial clause enables the insured to remain financially secured in any unforeseen situation which can cause their health to deteroriate without increase in annual premium. 
</li>
            <li>Insurance Company's Reputation  -  Past reputation especially w.r.t claim settlement of the insurance company is an important factor to be evaluated while deciding on health insurance policy. An insurance company's claim settlement ratio / vintage /  existing financial strength (solvency ratio) / overall market share are other factors to be considerd prior to purchasing health insurance. 
</li>
          </ul>
        </div>
        <p>&nbsp;</p>
        <h3>How many types of Health Insurance Covers are available in India?</h3>
        <ul>
          <li>An Individual Health Insurance policy - covers the medical treatment cost of a single person. This cover can be availed for self, parents apart from spouse and children.</li>
          <li>Family Floater Health Insurance policy  -  In this cover - a fixed / single amount of sum insured is available for all individuals under one policy. This entire sum can be disbursed for treatment of one person or more, after which no subsequent claims are covered in the event of another medical emergency.
</li>
          <li>Senior Citizen Health Insurance plan - this plan/policy is exclusively targetting senior citizens (above 60 years)   - the list of illnesses is customized by different insurers keeping in mind the age of this group
</li>
          <li>Health Insurance with Maternity Insurance cover -   Such insurance policies  enabke coverage for all pre and postnatal care expenses incurred during pregnancy are covered under maternity insurance. Even the medical bills of a new-born are included for the first three months.  However there is a waiting period of 2 years for these policies.  
</li>
          <li>Top-Up Health Insurance -  over and above your existing sum insured for medical insurance -one can opt for Top-Up health cover. This helps to increase the overall sum insured which you can use in case of any medical emergency. However - there is always a compulsory deductible amount.
</li>
          <li>Critical Illness -  A critical illness insurance policy provides coverage against such specific conditions - typically life threatening and having long / expensive medical treatment costs. Such covers offer financial protection for treatment / patient recovery. 
</li>
        </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
    )
  }

}