import React from 'react';
import './CustomerRightsPolicy.scss';

export class CustomerRightsPolicy extends React.Component<{}, { }> {

  render() {
    return (
        <>
          <section className="car-wrapper hero-unit">
            <div className="page-content">
              <div className="container">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="cr-content">
                    <div className="row">
                      <div className="col-lg-8 col-md-8 col-sm-7 car-register-num-content">
                        <div className="cr-header">
                          <h1 className="cr-header">Customer Rights Policy</h1>
                        </div>
                        <div className="car-register-num-content-image skeleton">
                          &nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-pad">
            <div className="container">
              <div className="row">
                <div className="col-md-10 col-md-offset-1">
                  <div>
                    <div className="content-info-list">
                      <h3 className="font-bold">Right to Equitable and Fair treatment</h3>
                      <div>Eezeeinsure.com will treat all its customers fairy and without any discrimination. Eezeeinsure.com will not treat any customer unfairly irrespective of the customer’s age, gender, religion, physical ability.
Eezeeinsure.com employees will always 
      <ul>
        <li>Strive to promote fair insurance selling and claims settlement practices with minimum benchmark standards</li>
        <li>Endeavour to adhere to laid down processes for insurance policy sales and claims handling.
</li>
        <li>Ensure all Eezeeinsure.com employees promptly attend to customer requests and deal with all customers courteously.
</li>
        <li>Eezeeinsure.com  employees will treat all customers fairly and will not discriminate any customer due to age, gender, religion, physical ability or financial status.
</li>
        <li>Ensure all services offered by Eezeeinsure.com are in accordance with India’s relevant laws and regulations.
</li>
         

      </ul>
</div>  

      <p>&nbsp;</p>
      <h3 className="font-bold">Right to Transparent and Fair Dealing</h3>
                      <div>
                      Eezeeinsure.com will strive to make all contracts and insurance policies are transparent, easy to understand by its customers. To achieve this, Eezeeinsure.com will
                      <ul>
                        <li>Ensure that dealings with customers, agents and channel partners are based on principles of equity, integrity, transparency and honesty 
</li>
                        <li>Inform its customers about the Policy Issuance / Amendment / Claim Lodgement  turnaround times, pricing, charges, terms and conditions as well as other detailed requirements for all services offered
</li>
                        <li>Cleary communicate and explain the various features and terms of execution of different services and products offered by Eezeeinsure.com
</li>
                        <li>Display clearly on the website the service offerings, terms and conditions 
</li>
                        <li>Update the website about any changes in service offerings and terms and conditions at-least 3 weeks before the proposed changes
</li>
                        <li>Provide precise information about different financial and non-financial penalties in case of breach of contract or breach of terms and conditions for services chosen by the customer or channel partner
</li>
<li>Communicate clearly the information pertaining to discontinuing of existing service or product atleast 3 weeks before actual date of discontinuing the service
</li>
<li>Display in its website the updated contact details of its offices or change in working hours or change in office address
</li>
<li>Ensure transparent and unambiguous marketing and promotional activities. Eezeeinsure.com will not indulge in any misleading advertising or promotional campaigns
</li>
<li>Not terminate any customer or change partner’s business relationship or contract without prior written notice of 30 days
</li>

                      </ul> 
</div>  
<p>&nbsp;</p>
      <h3 className="font-bold">Right to Customer Complaint Handling Process</h3>
                      <div>Customer has a genuine right to hold Eezeeinsure.com responsible for all services and products offered. To ensure this Eezeeinsure.com employees will
                        <ul>
                          <li> Acknowledge their mistakes promptly and make immediate efforts to correct these mistakes and errors
</li>
                          <li>Adopt and implement a robust customer complaint handling mechanism which included clearly communicating to a customer as to how lodge a complaint, to whom to address the complaint and the escalation mechanism if complaint is not satisfactorily resolved including escalation to insurance companies and IRDA.
</li>
                          <li>Ensure all complaints are closed only after obtaining written confirmation about resolution of the complaint from the customer
</li>
                          <li>Display in the website the complaint handling process and link where customer can log in a complaint. This should also allow customers easy accessibility to make complaints online or by email or by voice or in writing by post and acknowledging each complaint with a unique identification number
</li>
                          <li>Display in website the details of all top management employees to whom complaint can be escalated
</li>
                        </ul>
                        </div> 

                      
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
    )
  }

}