import React from 'react';
import { Header } from './components/header/Header';
import { Footer } from './components/footer/Footer';
import { ProductsList } from './components/productslist/ProductsList';
import { ReachUs } from './components/reachus/ReachUs';
import { WhyChooseUs } from './components/whychoooseus/WhyChooseUs';
import { WhatWeDo } from './components/whatwedo/WhatWeDo';
import { useEffect } from "react";
import './App.css';

import AOS from "aos";
import 'aos/dist/aos.css';

function App() {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div className="App">
      <Header/>
      <ProductsList/>
      <WhatWeDo/>
      <WhyChooseUs/>
      <ReachUs/>
      <Footer/>
    </div>
  );
}

export default App;
