import React from 'react';
import './HomeInsurance.scss';

export class HomeInsurance extends React.Component<{}, { }> {

  render() {
    return (
        <>
          <section className="car-wrapper hero-unit">
            <div className="page-content">
              <div className="container">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="cr-content">
                    <div className="row">
                      <div className="col-lg-8 col-md-8 col-sm-7 car-register-num-content">
                        <div className="cr-header">
                          <h1 className="cr-header">Home Insurance</h1>
                        </div>
                        <div className="car-register-num-content-image skeleton">
                          &nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-pad">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="text-sm-center mb-30">
                    <h2 className="h2-v2 text-left">Home Insurance: Buy/Renew Home Insurance Policy Online</h2>
                  </div>
                </div>
                <div className="col-md-10 col-md-offset-1">
                  <div>
                    <div className="content-info-list">
                      <h3 className="font-bold">What is Home Insurance?</h3>
                      <p></p>
                      <div>
          Home Insurance provides protection to your home against loss from fire, burglary and natural calamities. Home Insurance also has cover available for building /contents  including jewellery / domestic appliances etc. It also offers protection against burglary/ theft.
        </div>
        <p>&nbsp;</p>
        <h3>Why do we need Home Insurance?</h3>
        <div>
          Our Home has contents which need protection against natural calamities / accidents / theft etc. Apart from this, home also needs insurance cover for building. 
        </div>
        <p>&nbsp;</p>
        <h3>
          What is the nature and scope of Home Insurance?
        </h3>
        <div>
          A typical Home Insurance policy will have different sections covering specific perils /risks -   we are sharing different sections of a typical home insurance policy -  atleast 3 sections are mandatory including Section 1
          <ul>
            <li>Section 1 - Fire & Allied Perils -  Building cover /   contents of the house   /  risks due to Fire/Lightening /Explosion of gas in domestic appliances / Bursting and overflow of water tanks /  Riot Strike or Malicious Act  / Earthquake or Fire or Subsidence and Landslide /  Flood Inundation Storm Typhoon Tornado etc
</li>
            <li>Section 2   - this typically protects against Burglary & Housebreaking including theft /larceny.
</li>
            <li>Section 3 -  this covers for all risks (accident or misfortune while kept at home or worn anywhere subject to SI)  pertaining to Jewellery & Valuables.
</li>
            <li>Section 4 -  protection against accidental damage to plate glass.
</li>
            <li>Section 5 - protection against sudden and unforeseen mechanical /electrical breakdown of Domestic Appliances.
</li>
            <li>Section 6 - provides all risk cover for TV Set including video recorders.
</li>
            <li>Section 7 -  includes all risk cover for pedal cycle including third party injury/damage.
</li>
            <li>Section 8 -  provides cover for loss or damage to insured's baggage while travelling in India.
</li>
            <li>Section 9 -  this section provides personal accident cover - death or bodily injury within specified SI limits.
</li>
            <li>Section 10 -  provides liability cover for  third party bodily injury or property damage.
</li>

          </ul> 
        </div>
        <p>&nbsp;</p>
        <h3>What should be opted for in a Home Insurance Policy?</h3>
        <div>
          All the contents including Furniture, Fixtures, Fittings, Domestic Appliances, Jewellery etc.
        </div>
        <p>&nbsp;</p>
        <h3>What are exclusions in Home Insurance?</h3>
        <div>
          Home Insurance Policies have the following exclusions:
          <ul>
            <li>Wilful Conduct of the insured
</li>
            <li>Loss to money, securities, livestock, motor vehicle, documents due to Fire or House break-in
</li>
            <li>Theft of Jewellery from car (saloon) except when stolen while properly locked. Loss caused by moth, mildew is excluded
</li>
            <li>Scratches in plate glass are not covered
</li>
            <li>Loss due to prior manufacturing defect to Domestic Appliances
</li>
            <li>Loss to Laptop /PC due to wear and tear
</li>
            <li>Loss to pedal cycle due to mechanical breakdown
</li>
            <li>Loss of cash, jewellery loss within the municipal limits is excluded from Baggage Insurance cover
</li>
            <li>Suicide /Attempted Suicide / Bodily injury caused by breach of law by the insured
</li>
          </ul>
        </div>
        <p>&nbsp;</p>
        <h3>FAQs</h3>
        <div>
          <div className='font-bold'>Can a person staying on rent can take home insurance?</div>
          <div>Yes -  a tenant can take gome insurance but the cover will be limited to contents and not the building</div>

          <br/>
        <div className='font-bold'>Can I change the Sum Insured under Home Insurance  mid-term into the policy?</div>
        <div>
          Yes - some insurers allow you to increase or decrease the Sum Insured for following covers -  Building / Contents / Jewellery / Painting etc and additional premium is charged or premium refunded. 
        </div>
        <br/>
        <div className='font-bold'>What are the standard documents needed while lodging Home Insurance claim?</div>
        <div>Most insurers insist on claim being lodged by filling up claim form   and /or informing them by calling on toll-free numbers.  Claim form has to be filled along-with FIR copy and other specific documents as informed by the surveyor during his/her visit.
        </div>
        <br/>
        <div className='font-bold'>How do I select the Sum Insured?</div>
        <div>While deciding the Sum Insured for the building -  one must take insurance cover for the full cost of rebuilding the property including architect fee etc apart from cost of clearing away the debris / cost of obtaining sanctions for any new building regulations or by-laws.
        While assessing the sum insured for contents -  one must opt for replacement value except for Section 3 (All Risks) where it should be market value but for Jewellery it will be market value less 10% (making charges) or purchase cost.
        -  For all other sections -  sum insured can be decided by proposer of the policy.
      </div>
<br/>
       <div className='font-bold'>Who can buy Home Insurance Policy?</div>
        <div>Anyone who has insurable interest in a independent house /flat and is not a Kutcha construction is eligible to buy home insurance policy.  All risk covers are available for owners and tenants except that tenants can only opt for protection of the contents and not the building.
        </div>
        
        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
    )
  }

}