import React from 'react';
import './PCVInsurance.scss';

export class PCVInsurance extends React.Component<{}, { }> {

  render() {
    return (
        <>
          <section className="car-wrapper hero-unit">
            <div className="page-content">
              <div className="container">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="cr-content">
                    <div className="row">
                      <div className="col-lg-8 col-md-8 col-sm-7 car-register-num-content">
                        <div className="cr-header">
                          <h1 className="cr-header">Passenger Carrying Vehicle Insurance</h1>
                        </div>
                        <div className="car-register-num-content-image skeleton">
                          &nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-pad">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="text-sm-center mb-30">
                    <h2 className="h2-v2 text-left">Passenger Carrying Vehicle Insurance: Buy/Renew Passenger Carrying Vehicle Insurance Policy Online</h2>
                  </div>
                </div>
                <div className="col-md-10 col-md-offset-1">
                  <div>
                    <div className="content-info-list">
                      <h3 className="font-bold">What is Passenger Carrying Vehicle Insurance?</h3>
                      <p></p>
                      PCV Insurance protects the policy holder for damage to his/her PCV due to:
                      <ul>
                        <li>Natural disasters such as earthquake, flood, storm, etc.</li>
                        <li>Man-made disasters such as accident, theft, fire, riots, malicious act, etc.</li>
                        <li>While in transit by rail/road, lift, elevator</li>
                      </ul>
    <p>&nbsp;</p>
    <h3>What are the vehicles covered under PCV?</h3>
    The following variants of vehicles are covered under PCV:
    <ul>
      <li>Bus - School Bus / Private Tourist Bus / Buses for ferrying passengers </li>
      <li>Auto Rickshaw (3 Wheeler) - all types 3 wheelers transporting people</li>
      <li>Taxis - Taxis, Private Co. Commercial vehicles transporting people</li>
      <li>Van - Mini-bus for carrying tourists, School Van</li>
    </ul>
   
      <p>&nbsp;</p>
    <h3>What are  two types of PCV insurance ?</h3>
    <ul>
      <li>Third Party (or Liability Only policy):   this covers only the injury or death of a third person and/or damage to third person's property- on account of any accident caused by insured's PCV.  Third Party Insurance also includes compulsory personal accident cover for the owner-driver. This does not cover any damages to own PCV.  As per Indian laws, it is mandatory for every PCV owner to take Third Party Cover.</li>
      <li>Comprehensive (or Package policy): To offer protection against loss or damage to your PCV - you must purchase Comprehensive Policy covering Third Party and Own Damage covers. 
</li>
    </ul>
    
    <p>&nbsp;</p>
    <h3>What are the various insurance covers available for PCV insurance?</h3>
    PCV Comprehensive Policy covers damage to your PCV due to:
    <ul>
      <li>Accidents - any damage caused to own PCV due to accident or collision with any other vehicle   as well as any liability arising out of damage to or injury and death of a third party – property, vehicle and person.
</li>
      <li>PCV policy also provides financial coverage against theft. This policy protects against the cost arising in the event of the vehicle being stolen.
</li>
      <li>Third Party Damage - damage caused to third party vehicle or property by your PCV
</li>
      <li>Natural calamities like floods, earthq​uake, fire, thunderstorms, hailstorms, cyclones, lightening, earthquakes and more
</li>
      <li>Man-made calamities like vandalism, riots and terror attacks
</li>
      <li>Damage due to explosion, hailstorm, terrorism frost, inundation, self-ignition, tempest, tiot &amp; strikes, landlside, rockslide, typhoon, transit by rail / road / air
</li>
      <li>Personal Accident cover - this policy covers PA expenses for the owner-driver and passengers travelling in the PCV  in the event of an accident.
</li>
    </ul>
<p>&nbsp;</p>
    <h3>How is PCV Insurance Premium calculated?</h3>
    <div>PCV Insurance Premium depends upon the following factors. 
      <ul>
        <li>Insured Declared Value  (IDV)</li>
        <li>Cubic Capacity</li>
        <li>Geographical Zone</li>
        <li>Age of the PCV</li>
        <li>Add-on covers -  Loss or damage to accessories fitted in the vehicle such as stereos, fans, air-conditioners  /  personal accident cover to paid driver and passengers  /  legal liability of employees</li>
      </ul>
         </div>
      <p>&nbsp;</p>
    <h3>FAQs</h3>
    <div className='font-bold'>Who can buy PCV Insurance?</div>
    <div>Any PCV owner who has registered  PCV in his/her name with any Regional Transport Authority in India can buy PCV insurance.  
      PCV can be School PCV, Private Tourist PCV or vehicle which ferries passengers </div>
      <br/>
    <div className='font-bold'>Is Cashless settlement available for PCVs?</div>
    <div>
      Yes, Cashless settlement of claims is offered for PCVs across lage number of network garages. 
    </div>
    <br/>
    <div className='font-bold'>How is No Claim Bonus calculated for PCVs?</div>
    <div>
      Insurance Cos. offer a No Claim bonus (NCB) ranging from 20% to 50% discount on your premium during policy renewal if a claim is not filed during the previous term. The longer you go without filing a claim, the higher the discount with maximum capping at 50%.  
    </div>
    <br/>
    <div className='font-bold'>What are precautions to be taken while availing of PCV insurance?</div>
    <div>As a policy holder, one must take following precautions
       <ul>
         <li>Don't allow your policy to lapse and lose Nó Claim Bonus subsequently.  Please ensure your PCV insure is renewed before expiry date</li>
         <li>Don't hand over your PCV to anyone unless the person has a valid driving license.</li>
         <li>Don't drive your PCV or allow anyone to drive PCV under influence of drugs /alcohol /any other intoxicants</li>
         <li>Don't leave your keys in the PCV and/or leave any door unlocked when you leave the PCV.</li>
       </ul> 
    </div>
    <br/>
    <div className='font-bold'>How can a policy holder get additional discounts?</div>
    <div>Discounts are offered on premium for certain conditions, such as having an anti-theft device installed in the PCV /  voluntary deductible etc
    </div>
    <br/>
    <div className='font-bold'>What is Return to Invoice (RTI) cover?</div>
    <div>Reurn to Invoice is an add-on cover  which states that -  during car's theft or damages beyond repair,  then the policy holder gets the benefit of getting back the complete amount of the car's invoice value, including the cost of registering a new car and road tax
    </div>
    <br/>
    <div className='font-bold'>What are precautions to be taken while availing of car insurance?</div>
    <div>As a policy holder, one must take following precautions
      <ul>
        <li>Dont allow your policy to lapse and lose Nó Claim Bonus subsequently.  Please ensure your car insure is renewed before expiry date</li>
        <li>Dont hand over your car to anyone unless the person has a valid driving license.</li>
        <li>Dont drive your car or allow anyone to drive car under influence of drugs /alcohol /any other intoxicants</li>
        <li>Dont leave your keys in the car and/or leave any door unlocked when you leave the car.</li>
      </ul>
    </div>
    <br/>
    <div className='font-bold'>How can a policy holder get additional discounts?</div>
    <div>Discounts are offered on premiums for certain conditions, such as having an anti-theft device installed in the car
    </div>
    <br/>
    <div className="font-bold">What are typical exclusions in PCV insurance?</div>
    <div>Some typical exclusions in PCV insurance are:
      <ul>
        <li>In the case of a Third-Party Liability Only Policy, damages to own PCV will not be covered.</li>
        <li>Consequential Damages  -  Any damage arising not directly due to an accident is not covered  -   for example if after an accident - the damaged PCV is being used incorrectly and as a result of this - the engine gets damaged, then such a loss will not be covered</li>
        <li>Driving without valid license or drunk driving -   any loss or damage that occurs if the PCV's owner-driver is drunk or driving without a valid licence is not covered under any PCV insurance policy</li>
        <li> Contributory Negligence  -   Any damage or loss that occurs due to a contributory negligence of the owner-driver is excluded  - for example if the PCV is driven when there is an existing flood like situation is not covered</li>
      </ul>
      
      
      
     
    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
    )
  }

}