import React from 'react';
import './header.scss';

export class FranchiseMenu extends React.Component {

  render() {
    return (
      <div className="navbar-dropdown-inner2">
        <div className="header-container header-container-claims">
          <div className="header-products header-claims">
            <div className="header-products__items">
              <div className="header-products__items-image-section">
                <p className="product-name"></p>
              </div>
              <div className="sub-products-container">
                <ul className="sub-products-container-list claims-container-list">
                  <li>
                    <a href="#">Mumbai</a>
                  </li>
                  <li>
                    <a href="#">Chandigarh</a>
                  </li>
                  <li>
                    <a href="#">Mohali</a>
                  </li>
                  <li>
                    <a href="#">Panchkula</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}